var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.page)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("my-packages-title")))]),(!!_vm.page.doc.content)?_c('div',{staticClass:"row mb-3"},[_c('page-title',{attrs:{"subheading":_vm.page.doc.content}})],1):_vm._e(),_c('packages-table',{staticClass:"mb-3",attrs:{"table-title":"ACTIVE LOYALTY PROGRAM","packages":_vm.page.viewer.packages
    .filter(function (p) { return p.active; })
    .map(function (p) { return this$1.transformDataActive(p); }),"header":_vm.activeHeaders,"locked-rate":_vm.page.viewer.depositAddress,"globalStats":_vm.page.globalStats}}),_c('packages-sell-table',{staticClass:"my-3",attrs:{"table-title":"ACTIVE DIVIDEND PROGRAM","packages":_vm.page.viewer.packages
    .filter(function (p) { return !p.active && (p.sellAt) && (p.sellBackStatus < 2 || p.sellBackStatus === 9); })
    .map(function (p) { return this$1.transformDataExpired(p); }),"header":_vm.expiredHeaders,"globalStats":_vm.page.globalStats}}),_c('packages-history-table',{staticClass:"my-3",attrs:{"table-title":"HISTORY","packages":_vm.page.viewer.packages
    .filter(function (p) { return !p.active && (!(p.sellAt) || (p.sellBackStatus > 1 && p.sellBackStatus !== 9)); })
    .map(function (p) { return this$1.transformDataExpired(p); }),"header":_vm.historyHeaders}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }