<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{ tableTitle }}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table dense :headers="header" :items="packages.sort(sortHistory)" item-key="id">
            <template v-slot:items="props">
                <td v-if="props.item.user" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    {{ props.item.user.username }}
                    <span class="font-weight-bold float-right" v-if="props.item.user.viewerLeg === 1">L</span>
                    <span class="font-weight-bold float-right" v-else-if="props.item.user.viewerLeg === 2">R</span>
                </td>
                <td :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)"><span
                        v-bind:title="getTitle(props.item)">{{ props.item.id | formatID }}</span></td>
                <td class="date-time-width" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    {{ props.item.expiresAt | formatDate }}
                    <br>
                    <span v-if="props.item.sellAt !== null">{{ props.item.sellAt | formatDate }}</span>
                    <span v-else>/</span>
                </td>
                <td class="date-time-width" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    {{ props.item.sellBackDate | formatDate }}
                </td>
                <td :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    <button class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-' + props.item.color" disabled>
                        {{ props.item.packageName }}
                        <span v-if="props.item.noCommission === 0"
                            class="badge badge-warning badge-dot badge-dot-lg badge-dot-text">NC</span>
                    </button>
                </td>
                <td v-if="props.item.button" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left pr-2">
                                    <div class="widget-numbers fsize-1" :class="'text-' + props.item.color">{{
                                        props.item.diff }}%</div>
                                </div>
                                <div class="widget-content-right w-100">
                                    <div class="progress-bar-xs progress">
                                        <div class="progress-bar" :class="'bg-' + props.item.color" role="progressbar"
                                            :aria-valuenow="props.item.diff" aria-valuemin="0" aria-valuemax="100"
                                            :style="'width: ' + props.item.diff + '%;'">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="value-min-width" v-if="props.item.freshValueInTokens"
                    :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    <span>{{ props.item.freshValueInTokens[0] | formatHtl }}</span> HTL
                </td>
                <td class="value-min-width" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">
                    <span>{{ props.item.totalValueInTokens | formatHtl }}</span> HTL / <span>{{
                        props.item.valueInFiat.reduce((accumulator, value) => {
                            return accumulator + value;
                        }, 0) | formatEur }}</span> EUR
                </td>
                <td class="text-center" :style="getBGColor(props.item.sellAt, props.item.sellBackStatus)">

                    <div v-if="props.item.sellAt !== null && props.item.sellBackStatus == 3" role="group" style="background-color: green;
                                color: white;
                                padding-left: 10px;
                                padding-right: 10px;
                                padding-top: 5px;
                                padding-bottom: 5px;">CONVERTED</div>
                    <div v-else-if="props.item.sellAt !== null && props.item.sellBackStatus == 2" role="group" style="background-color: steelblue;
                                color: white;
                                padding-left: 10px;
                                padding-right: 10px;
                                padding-top: 5px;
                                padding-bottom: 5px;">SOLD</div>
                    <div v-else-if="props.item.sellBackStatus == 10" role="group" style="background-color: #f3702a;
                                color: white;
                                padding-left: 10px;
                                padding-right: 10px;
                                padding-top: 5px;
                                padding-bottom: 5px;">SECURE SELL</div>
                    <div v-else role="group" style="background-color: darkgray;
                                color: white;
                                padding-left: 10px;
                                padding-right: 10px;
                                padding-top: 5px;
                                padding-bottom: 5px;">EXPIRED</div>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook);
export default {
    name: "PackagesHistoryTable",
    components: {
    },
    data() {
        return {
            search: "",
            vouhers: null
        };
    },
    props: [
        "packages",
        "tableTitle",
        "header",
        "lockedRate"
    ],
    beforeMount() {
    },
    methods: {

        sortHistory(a, b) {
            if (a.sellBackDate === null && b.sellBackDate === null) {
                if ((+new Date(String(a.id)) - +new Date(String(b.id))) > 0)
                    return -1;
                else if ((+new Date(String(a.id)) - +new Date(String(b.id))) < 0)
                    return 1;
                else
                    return -1;
            }
            else if (a.sellBackDate === null) {
                return 1;
            }
            else if (b.sellBackDate === null) {
                return -1;
            }

            if ((+new Date(String(a.sellBackDate)) - +new Date(String(b.sellBackDate))) > 0)
                return -1;
            else if ((+new Date(String(a.sellBackDate)) - +new Date(String(b.sellBackDate))) < 0)
                return 1;
            return -1;

        },
        getBGColor(sellAt, sellBackStatus) {

            //converted
            if (sellAt !== null && sellBackStatus == 3) {
                return 'background-color:#98FF98;';//mint green     
            }
            //sold
            else if (sellAt !== null && sellBackStatus == 2) {
                return 'background-color:#9AFEFF;';//electric blue     
            }
            //secure sell
            else if (sellBackStatus == 10) {
                return 'background-color:#ffcd89;';//     
            }

            return '';


        },
        getTitle(item) {
            var upgradeAt = '';

            if (item.upgradedAt.length > 0) {
                upgradeAt = 'Upgrades:\r\n   ';
                upgradeAt += item.upgradedAt.join('\r\n   ')

            }
            return 'Date of activation: ' + item.id + '\r\n'
                + 'Gen.: ' + item.packageType + '\r\n'
                + upgradeAt;
        },
        getPackageName(type) {
            return (type < 0 || type > 6) ? this.$i18n.t("unknown") : this.packageNames[type];
        },
        getPackageColor(type) {
            switch (type) {
                case 0:
                    return "primary";
                case 1:
                    return "primary";
                case 2:
                    return "primary";
                case 3:
                    return "primary";
                case 4:
                    return "success";
                case 5:
                    return "success";
                case 6:
                    return "success";
                default:
                    return "danger";
            }
        },
        onUpdate() {
            this.$emit('onUpdate')
        }
    }
}
</script>
<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px !important;
    height: 16px !important;
    color: white !important;
    border-radius: 50px !important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}

.badge-btn {
    min-width: 110px;
    border-radius: 50px !important;
}

.date-time-width {
    min-width: 150px;
}

.value-min-width {
    min-width: 150px;
}
</style>